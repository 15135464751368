body {
  margin: 0;
  font-family: "Baloo Chettan 2";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Urbanist,
    "Baloo Chettan 2";
}

/* remove scrollbar from the categories section  */
.categories-slider::-webkit-scrollbar {
  display: none;
}
